import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

const ShareLinks = () => {
  const data = useStaticQuery(graphql`
    query {
      allFooterJson(
        filter: { BottomLinks: { elemMatch: { CTAText: { ne: null } } } }
      ) {
        nodes {
          SocialMedia {
            CTALink
            Image
            Title
          }
          BottomLinks {
            CTALink
            CTAText
          }
          AndroidCTALink
          IOSCTALink
        }
      }
    }
  `);

  return (
    <>
      <div className="col-xl-3 col-lg-3 col-md-4">
        <div className="d-flex flex-column">
          <div className="text-muted fw-airlight fw-500 mb-3">
            Follow Us
            <span>
              {data.allFooterJson.nodes.map((node) => {
                return node.SocialMedia
                  ? node.SocialMedia.map((q) => (
                      <>
                        <Link to={q.CTALink} className="ps-1" target="_blank">
                          <img src={q.Image} alt={q.Title} className="w-auto" />
                        </Link>
                      </>
                    ))
                  : null;
              })}
            </span>
          </div>
        </div>
        {data.allFooterJson.nodes.map((node) => {
          return node.BottomLinks
            ? node.BottomLinks.map((q) => (
                <>
                  <div className="d-flex flex-column">
                    <Link
                      to={q.CTALink}
                      className="text-muted fw-airlight fw-500"
                    >
                      {q.CTAText}
                    </Link>
                  </div>
                </>
              ))
            : null;
        })}

        <div className="d-flex">
          {data.allFooterJson.nodes.map((node, index) => {
            return (
              <>
                <a href={node.AndroidCTALink} target="_blank"className="nav-link ps-0">
                  <img
                    src="../img/Googleplay.svg"
                    alt="Google Play"
                    width="90"
                    height="48"
                  />
                </a>
                <Link
                  to={node.IOSCTALink}
                  className="nav-link align-self-center ps-0"
                >
                  <img
                    src="../img/Playstore.svg"
                    alt="Play Store"
                    width="90"
                    height="48"
                  />
                </Link>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ShareLinks;

// export page query
