import { Link, navigate } from "gatsby";
import React from "react";
import { Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { graphql, useStaticQuery } from "gatsby";
import AppLinks from "./App_links";
import ShareLinks from "./Share_Links";
import { StaticImage } from "gatsby-plugin-image";

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      allFooterJson(
        filter: { BottomLinksCompany: { elemMatch: { CTAText: { ne: null } } } }
      ) {
        nodes {
          BottomLinksCompany {
            CTALink
            CTAText
          }
        }
      }
    }
  `);
  return (
    <footer>
      <Container className="pt-5 ps-5 ps-xl-3">
        <div className="footer-top">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="d-flex flex-column pb-4">
                <p className="text-black fw-500">The Company</p>
                {data.allFooterJson.nodes.map((node) => {
                  return node.BottomLinksCompany
                    ? node.BottomLinksCompany.map((q) => (
                        <>
                          <Link
                            to={q.CTALink}
                            className="text-muted fw-airlight fw-500"
                          >
                            {q.CTAText}
                          </Link>
                        </>
                      ))
                    : null;
                })}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="d-flex flex-column pb-4">
                <AppLinks />
              </div>
            </div>
            <ShareLinks />
            <div className="col-xl-3 col-lg-3 col-md-5 rec-contact py-4 px-5 ">
              <h4 className="fw-400">Say hi or ask a question</h4>
              <Button
                className="=text-start mt-4"
                onClick={() => navigate("/contactus")}
              >
                Contact Us{" "}
                <FontAwesomeIcon icon={faArrowRight} className="ms-4 fa-sm" />{" "}
              </Button>
            </div>
          </div>
        </div>
        <div className="d-none d-sm-block ">
          <div className="row justify-content-between py-4 footer-line">
            <div className="col-md-6 text-muted align-self-center">
              {/* <p className="mb-0">
                Copyright {new Date().getFullYear()} - The BOGO App, Inc
              </p >*/}
            </div>
            <div className="col-md-6 ms-auto text-end">
              <Link to="/">
                <img
                  src="../img/Bogo-Black.svg"
                  alt="logo"
                  className="w-auto"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row text-center py-4 footer-line d-block d-sm-none pe-5 ">
          <div className="col-md-6 text-muted align-self-center">
            {/* <p className="mb-0">
              {" "}
              Copyright {new Date().getFullYear()} - The BOGO App, Inc
            </p>*/}
          </div>
          <div className="col-md-6">
            <Link to="/">
              <img src="../img/Bogo-Black.svg" alt="Bogo" className="w-auto" />
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
}
