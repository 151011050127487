import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const AppLinks = () => {
    const data = useStaticQuery(graphql`
    query  {
        allFooterJson(filter: {BottomLinksApp: {elemMatch: {CTAText: {ne: null}}}}) {
            nodes {
              BottomLinksApp {
                CTALink
                CTAText
              }
            }
          }
        }
  `)


    return (<>
        <p className="fw-500">The App</p>
        {data.allFooterJson.nodes.map(node => {
            return node.BottomLinksApp
                ? node.BottomLinksApp.map(q => (
                    <>
                        <Link to={q.CTALink} className="text-muted fw-airlight fw-500">
                            {q.CTAText}
                        </Link>
                    </>
                ))
                : null
        })}
    </>
    )
}

export default AppLinks

// export page query
